import { v4 as uuid } from "uuid";
import { IComponent } from "../../ecs/component";
import { CardName, CARD_NAME } from "./types";

export function cardName(name: CardName): IComponent<CardName> {
  return {
    id: uuid(),
    data: name,
    name: CARD_NAME
  };
}
