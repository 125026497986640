import { CardCost, CARD_COST } from "./cardCost/types";
import { CardGroup, CARD_GROUP } from "./cardGroup/types";
import { CardName, CARD_NAME } from "./cardName/types";
import { Cards, CARDS } from "./cards/types";
import { Dimensionality, DIMENSIONALITY } from "./dimensionality/types";
import { Hand, HAND } from "./hand/types";
import { Position, POSITION } from "./position/types";

export { CardCost, CARD_COST } from "./cardCost/types";
export { CardName, CARD_NAME } from "./cardName/types";
export { Position, POSITION } from "./position/types";

export type COMPONENT_TYPES =
  | CardCost
  | CardGroup
  | CardName
  | Cards
  | Dimensionality
  | Hand
  | Position;

export type COMPONENTS = {
  [CARD_COST]: CardCost;
  [CARD_GROUP]: CardGroup;
  [CARD_NAME]: CardName;
  [CARDS]: Cards;
  [DIMENSIONALITY]: Dimensionality;
  [HAND]: Hand;
  [POSITION]: Position;
};
