import { entitiesWith, IEntity, getComponent } from "../ecs/entity";
import { System } from "../ecs/system";
import {
  CARD_COST,
  CARD_NAME,
  POSITION,
  COMPONENT_TYPES,
  COMPONENTS
} from "../components";
import { pipe } from "fp-ts/lib/pipeable";
import { map, Option } from "fp-ts/lib/Option";
import { DIMENSIONALITY } from "../components/dimensionality/types";
import { IComponent } from "../ecs/component";

function getComponents<K extends keyof COMPONENTS>(
  cs: Array<K>,
  ent: IEntity
): { [key in K]: Option<IComponent<COMPONENTS[K]>> } {
  return cs
    .map(componentType => {
      return [componentType, pipe(getComponent(componentType, ent))] as [
        K,
        Option<IComponent<COMPONENTS[K]>>
      ];
    })
    .reduce((componentMap, [componentType, component]) => {
      return Object.assign({}, componentMap, { [componentType]: component });
    }, {}) as { [key in K]: Option<IComponent<COMPONENTS[K]>> };
}

export const renderCards: (ctx: CanvasRenderingContext2D) => System = ctx => (
  es: IEntity[]
) => {
  const cards = entitiesWith(CARD_COST, es);
  cards.map(cardEntity => {
    getComponents([CARD_COST, CARD_NAME, POSITION, DIMENSIONALITY], cardEntity);
    pipe(
      getComponent(CARD_COST, cardEntity),
      map(cardCost => {
        pipe(
          getComponent(CARD_NAME, cardEntity),
          map(cardName => {
            pipe(
              getComponent(POSITION, cardEntity),
              map(({ data: pos }) => {
                pipe(
                  getComponent(DIMENSIONALITY, cardEntity),
                  map(dim => {
                    ctx.save();
                    ctx.translate(pos.x, pos.y);
                    ctx.strokeStyle = "black";
                    ctx.lineWidth = 2;
                    ctx.strokeRect(0, 0, dim.data.w, dim.data.h);
                    ctx.translate(8, 24);
                    ctx.font = "bold 12pt Roboto";
                    ctx.fillText(cardName.data, 0, 0, 240);
                    ctx.translate(-16, -16);
                    ctx.translate(240 - 24, 0);
                    ctx.strokeRect(0, 0, 24, 24);
                    ctx.translate(12, 14);
                    ctx.textAlign = "center";
                    ctx.textBaseline = "middle";
                    ctx.fillText(cardCost.data.dust.toString(), 0, 0);
                    ctx.restore();
                  })
                );
              })
            );
          })
        );
      })
    );
  });
};
