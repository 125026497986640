import { IEntity, createEntity } from "../../ecs/entity";
import { cardCost, dustCost } from "../../components/cardCost";
import { cardName } from "../../components/cardName";
import { position } from "../../components/position";
import { cardDimensionality } from "../cardDimensionality";

export const harvester: () => IEntity = () =>
  createEntity([
    cardCost(dustCost(1)),
    cardName("Dust Harvester"),
    position(0, 480),
    cardDimensionality()
  ]);
