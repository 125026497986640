import { System } from "../ecs/system";
import {
  IEntity,
  entitiesWith,
  entitiesWithComponents,
  getComponent
} from "../ecs/entity";
import { POSITION, Position } from "../components";
import {
  DIMENSIONALITY,
  Dimensionality
} from "../components/dimensionality/types";
import { pipe } from "fp-ts/lib/pipeable";
import { chain, map } from "fp-ts/lib/Option";
import { IComponent } from "../ecs/component";

function isMouseWithin(
  {
    position,
    dimensionality
  }: {
    position: IComponent<Position>;
    dimensionality: IComponent<Dimensionality>;
  },
  event: MouseEvent
): boolean {
  return (
    position.data.x < event.x &&
    position.data.x + dimensionality.data.w > event.x &&
    position.data.y < event.y &&
    position.data.y + dimensionality.data.h > event.y
  );
}

export const moveCards: () => System = () => (es: IEntity[]) => {
  const movableCards = entitiesWithComponents([POSITION, DIMENSIONALITY], es);

  window.onmousedown = ev => {
    // find if our event is happening inside any cards
    movableCards.find(card => {
      pipe(
        getComponent(POSITION, card),
        chain(posComp =>
          pipe(
            getComponent(DIMENSIONALITY, card),
            map(
              dimComp =>
                [posComp, dimComp] as [
                  IComponent<Position>,
                  IComponent<Dimensionality>
                ]
            )
          )
        ),
        map(([position, dimensionality]) => {
          function onMouseMove(ev: MouseEvent) {
            position.data.x += ev.movementX;
            position.data.y += ev.movementY;
          }

          function onMouseUp() {
            window.removeEventListener("mousemove", onMouseMove);
          }
          if (isMouseWithin({ position, dimensionality }, ev)) {
            window.addEventListener("mousemove", onMouseMove);
            window.addEventListener("mouseup", onMouseUp);
          }
        })
      );
    });
  };
};
