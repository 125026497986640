import { v4 as uuid } from "uuid";
import { IComponent } from "../../ecs/component";
import { Position, POSITION } from "./types";

export function position(x: number, y: number): IComponent<Position> {
  return {
    data: { x, y },
    id: uuid(),
    name: POSITION
  };
}
