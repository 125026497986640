import { v4 as uuid } from "uuid";
import { IComponent } from "../../ecs/component";
import { CardCost, CARD_COST } from "./types";

export function dustCost(amt: number): CardCost {
  return {
    dust: amt
  };
}

export function cardCost(costs: CardCost): IComponent<CardCost> {
  return {
    id: uuid(),
    name: CARD_COST,
    data: costs
  };
}
