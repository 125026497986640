import { v4 as uuid } from "uuid";
import { COMPONENT_TYPES, COMPONENTS } from "../components";
import { IComponent } from "./component";
import { UUID } from "./util";
import { Option, fromNullable } from "fp-ts/lib/Option";

export interface IEntity {
  id: UUID;
  components: Array<IComponent<COMPONENT_TYPES>>;
}

export function createEntity(
  components: Array<IComponent<COMPONENT_TYPES>>
): IEntity {
  return {
    id: uuid(),
    components
  };
}

/** find an entity with the requested component */
export function entitiesWith(componentName: string, es: IEntity[]) {
  return es.filter(ent =>
    ent.components.some(comp => comp.name === componentName)
  );
}

/** find an entity which has every one of the requested components */
export function entitiesWithComponents(
  componentNames: Array<string>,
  es: IEntity[]
) {
  return es.filter(ent =>
    componentNames.every(name => ent.components.some(c => c.name === name))
  );
}

export function getComponent<N extends keyof COMPONENTS>(
  componentName: N,
  e: IEntity
): Option<IComponent<COMPONENTS[N]>> {
  return fromNullable(
    e.components.find(c => c.name === componentName)
  ) as Option<IComponent<COMPONENTS[N]>>;
}
