import { v4 as uuid } from "uuid";
import { IComponent } from "../../ecs/component";
import { Dimensionality, DIMENSIONALITY } from "./types";

export function dimensionality(
  w: number,
  h: number
): IComponent<Dimensionality> {
  return {
    data: { w, h },
    name: DIMENSIONALITY,
    id: uuid()
  };
}
