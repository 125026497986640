import { fromNullable, map, Option, chain } from "fp-ts/lib/Option";
import { pipe } from "fp-ts/lib/pipeable";
import { harvester } from "./cards/harvester";
import { renderCards } from "./systems/renderCards";
import { moveCards } from "./systems/moveCards";
import { clearScreen } from "./systems/clearScreen";

function setUpCanvas(): Option<CanvasRenderingContext2D> {
  return pipe(
    fromNullable(document.getElementById("canvas")),
    map((canvas: HTMLCanvasElement) => {
      // set the canvas to the same size as the window
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      return canvas;
    }),
    chain((canvas: HTMLCanvasElement) => {
      return fromNullable(canvas.getContext("2d"));
    })
  );
}

pipe(
  setUpCanvas(),
  map(context => {
    const entities = [harvester()];
    setInterval(() => {
      moveCards()(entities);
      clearScreen(context)(entities);
      renderCards(context)(entities);
    }, 1000 / 30);
  })
);
